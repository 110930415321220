var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-modal",
        {
          ref: "addProject",
          attrs: {
            id: "addProject",
            title: "Add Project",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c("p", { staticClass: "text-muted" }, [
                _vm._v("Enter project information")
              ]),
              _c("label", { attrs: { for: "" } }, [_vm._v("Project Name")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-user" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "projectname",
                      placeholder: "Project Name"
                    },
                    model: {
                      value: _vm.projectname,
                      callback: function($$v) {
                        _vm.projectname = $$v
                      },
                      expression: "projectname"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("projectname"),
                    expression: "errors.has('projectname')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("projectname"),
                      expression: "errors.has('projectname')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The project name is required.")]
              ),
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Project Start Date")
              ]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-calendar" })
                      ])
                    ],
                    1
                  ),
                  _c("datepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "rt-date-picker-project w-50",
                    class: { "is-danger": _vm.errors.has("p_start_date") },
                    attrs: {
                      name: "p_start_date",
                      placeholder: "Dropdown",
                      format: "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.p_start_date,
                      callback: function($$v) {
                        _vm.p_start_date = $$v
                      },
                      expression: "p_start_date"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("p_start_date"),
                    expression: "errors.has('p_start_date')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("p_start_date"),
                      expression: "errors.has('p_start_date')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The project start date is required.")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Create Project")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("h4", { staticClass: "d-inline" }, [
                    _c("i", { staticClass: "fal fa-folder" }),
                    _vm._v(" All Project")
                  ]),
                  _vm.$session.get("user") === "accountant"
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.addProject",
                              modifiers: { addProject: true }
                            }
                          ],
                          staticClass: "float-right btn btn-success"
                        },
                        [
                          _vm._v("Add Project "),
                          _c("i", { staticClass: "fa fa-plus" })
                        ]
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c("v-client-table", {
                    staticClass: "statusTable",
                    attrs: {
                      columns: _vm.columns,
                      data: _vm.projectData,
                      options: _vm.options,
                      theme: _vm.theme,
                      id: "dataTable"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "status",
                        fn: function(props) {
                          return _c("div", {}, [
                            props.row.status === 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_vm._v("Active")]
                                  )
                                ])
                              : _vm._e(),
                            props.row.status !== 0
                              ? _c("span", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-danger w-100"
                                    },
                                    [_vm._v("Inactive")]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }
                      },
                      {
                        key: "projectname",
                        fn: function(props) {
                          return _c(
                            "div",
                            {},
                            [
                              _vm.$session.get("user") != "accountant"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Project Manager Project",
                                          params: { id: props.row.projectid }
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.setActiveProject(
                                            props.row.projectname
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(props.row.projectname) +
                                          " (" +
                                          _vm._s(props.row.ptn) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$session.get("user") === "accountant" &&
                              !_vm.$route.meta.is_resource
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Accountant Project",
                                          params: { id: props.row.projectid }
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.setActiveProject(
                                            props.row.projectname
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(props.row.projectname) +
                                          " (" +
                                          _vm._s(props.row.ptn) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$session.get("user") === "accountant" &&
                              _vm.$route.meta.is_resource
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "Accountant Project Resource",
                                          params: { id: props.row.projectid }
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.setActiveProject(
                                            props.row.projectname
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(props.row.projectname) +
                                          " (" +
                                          _vm._s(props.row.ptn) +
                                          ")"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }