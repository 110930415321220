<template>
  <div class="animated fadeIn">

    <b-modal id="addProject" ref="addProject" title="Add Project" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit">
        <p class="text-muted">Enter project information</p>
        <label for="">Project Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="projectname" v-model="projectname" class="form-control" placeholder="Project Name" />
        </b-input-group>
        <i v-show="errors.has('projectname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('projectname')" class="help is-danger">The project name is required.</span>
        <label for="">Project Start Date</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-calendar"></i></b-input-group-text>
          </b-input-group-prepend>
          <!-- <b-form-input type="date" v-validate="'required'" name="p_start_date" v-model="p_start_date" class="form-control" placeholder="Project Start Date" /> -->
          <datepicker :class="{'is-danger': errors.has('p_start_date')}" class="rt-date-picker-project w-50" name="p_start_date" placeholder="Dropdown" v-validate="'required'" format="yyyy-MM-dd" v-model="p_start_date"></datepicker>
        </b-input-group>
        <i v-show="errors.has('p_start_date')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('p_start_date')" class="help is-danger">The project start date is required.</span>
        <b-button variant="success" class="mt-4" type="submit" block>Create Project</b-button>
      </form>
    </b-modal>

    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-inline"><i class="fal fa-folder"></i> All Project</h4>
          <button v-if="$session.get('user')==='accountant'" v-b-modal.addProject class="float-right btn btn-success">Add Project <i class="fa fa-plus"></i></button>
          <hr>
          <v-client-table class="statusTable" :columns="columns" :data="projectData" :options="options" :theme="theme" id="dataTable">
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status === 0">
                <button class="btn btn-sm btn-success w-100">Active</button>
              </span>
              <span v-if="props.row.status !== 0">
                <button class="btn btn-sm btn-danger w-100">Inactive</button>
              </span>
            </div>
            <div slot="projectname" slot-scope="props">
              <router-link v-if="$session.get('user')!='accountant'" v-on:click.native="setActiveProject(props.row.projectname)" :to="{ name: 'Project Manager Project', params: { id: props.row.projectid }}">
              {{props.row.projectname}} ({{props.row.ptn}})</router-link>

              <router-link v-if="$session.get('user')==='accountant' && !$route.meta.is_resource"  v-on:click.native="setActiveProject(props.row.projectname)" :to="{ name: 'Accountant Project', params: { id: props.row.projectid }}">
              {{props.row.projectname}} ({{props.row.ptn}})</router-link>

              <router-link v-if="$session.get('user')==='accountant' && $route.meta.is_resource"  v-on:click.native="setActiveProject(props.row.projectname)" :to="{ name: 'Accountant Project Resource', params: { id: props.row.projectid }}">
              {{props.row.projectname}} ({{props.row.ptn}})</router-link>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
import Datepicker from 'vuejs-datepicker'
import jwt from 'jwt-decode'

Vue.use(ClientTable)

export default {
  name: 'AllProjects',
  components: {
    ClientTable,
    Event,
    Datepicker
  },
  data () {
    return {
      projectname: '',
      p_start_date: '',
      columns: ['status', 'projectname'],
      projectData: [{
        status: '',
      }],
      options: {
        headings: {
          projectname: 'Project Name',
          ptn: 'Number',
        },
        sortable: ['status', 'projectname', 'creation_date'],
        filterable: ['status', 'projectname', 'creation_date'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      id: "",
      error: false,
      passworderror: false,
      projectData: [],
      unique: false,
      passlimit: false,
      wtsAssignmentData: [],
      wtsWellFilteredData: [],
      status: null,
      sendIdModal: null,
      pwid: '',
      projectid: '',
      acc_project:null
    }
  },
  methods: {
    setActiveProject(val) {
      this.$session.set('projectname', val)
    },
    sendInfo(id) {
      false;
      var val = id;
      let data = this.wtsData;
      var id = data.findIndex(function(item, i){
        return item.wtsid === val
      });

      this.id = this.wtsData[id]['wtsid'];
      this.fname = this.wtsData[id]['fname'];
      this.lname = this.wtsData[id]['lname'];
      this.email = this.wtsData[id]['email'];
      this.status = this.wtsData[id]['status'];
      this.fax = this.wtsData[id]['fax'];
      this.phone = this.wtsData[id]['phone'];
    },
    sendWellId(pwid, projectid) {
      this.pwid = pwid;
      this.projectid = projectid;
    },
    findWell(id) {
      this.$http.get('/wts/get/wells/' + id)
      .then(response => {
        if (response.status === 200) {
          this.wtsWellData = response.data.result;
          this.wtsWellFilteredData = this.wtsWellData.filter(o => o.projectid === id);
        }
      })
      .catch(error => {
      })
    },
    clearErrors() {
      this.error = false;
      this.assignerror = false;
      this.editassignerror = false;
    },
    loadData() {
      this.$http.get('/wts/get/projects')
      .then(response => {
        this.acc_project = 0;
        if(this.$route.name == "Accountant Project Resources"){
          this.acc_project = 1;
          this.projectData = response.data.result.filter(x=>x.acc_project == this.acc_project);
        }
        else if(this.$route.name == 'Accountant Projects') {
           this.projectData = response.data.result.filter(x=>x.acc_project == 0);
        } else {
          this.projectData = response.data.result;
        }

      })
      .catch(error => {
      })
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            projectname: this.projectname,
            p_start_date: this.$moment(this.p_start_date).format("YYYY-MM-DD"),
            acc_project: this.acc_project
          })

          this.$http.post('/manager/create/project', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Project created successfully.', {type: 'success', duration: '3000'})
              this.$refs.addProject.hide();

              let wtsid = jwt(this.$session.get('jwt')).wtsid;

              console.log("response from creating project");

              let data = JSON.stringify({
                projectid: response.data.result.projectid,
                wtsid: wtsid,
                passlimit: 1,
              })


              this.$http.post('/manager/create/wts_project', data)
              .then(response => {
                if (response.status === 200) {
                  this.$toasted.show('Assignment Created', {icon: 'fa-check', type: 'success', duration: '3000'})
                  window.location.reload();

                }
              })
              .catch(error => {
                this.$toasted.show('Assignment Failed ' + error, {icon: 'fa-check', type: 'error', duration: '3000'})
              })

              /* this.$router.push('/manager/projects') */
            }
          })
          .catch(error => {
            this.$toasted.show('Error. ' + error, {type: 'error', duration: '3000', err : error})
          })
          return;
        }
      });
    }
  },
  mounted: function () {
    this.loadData();
  },
}
</script>

<style>
  .rt-date-picker-project input {
    padding: 0.3em 0.5em !important;
  }
</style>
